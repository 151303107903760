<template>
  <div id="practice" class="container-xxl">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 order-lg-1 order-sm-2">
        <span class="image"></span>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 order-lg-2 order-sm-1">
        <h5>{{ $t("practice.teaser") }}</h5>
        <h2 class="text-primary">{{ $t("practice.title") }}</h2>
        <span v-html="$t('practice.text')"></span>
        <div class="row">
          <div class="col">
            <div style="border: 1px solid #378ac4; padding: 10px">
              <table width="100%">
                <tr>
                  <td class="icon text-primary">
                    <i class="flaticon-dental-3"></i>
                  </td>
                  <td>
                    <div class="text-primary">
                      <h3>4500</h3>
                      <div style="color: black" v-html="$t('practice.patients')"></div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col">
            <div style="border: 1px solid #378ac4; padding: 10px">
              <table width="100%">
                <tr>
                  <td class="icon text-primary">
                    <i class="flaticon-dental-care-6"></i>
                  </td>
                  <td>
                    <div class="text-primary">
                      <h3>6000</h3>
                      <div style="color: black" v-html="$t('practice.treatments')"></div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col">
            <div style="border: 1px solid #378ac4; padding: 10px">
              <table width="100%">
                <tr>
                  <td class="icon text-primary">
                    <i class="flaticon-calendar"></i>
                  </td>
                  <td>
                    <div class="text-primary">
                      <h3>25</h3>
                      <div style="color: black" v-html="$t('practice.experience')"></div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "Practice",
  components: {
    Footer,
  },
};
</script>

<style scoped>
#practice {
  padding-top: 75px;
  padding-bottom: 50px;
}

.image {
  display: block;
  height: 50vh;
  background-color: aliceblue;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/img/sam-moqadam-I-kDEBUMAaQ-unsplash.jpg");
}

h2 {
  margin-bottom: 30px;
}

table {
  border: 0;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  line-height: normal;
}

table td.icon {
  font-size: 36px;
  padding-right: 10px;
  font-weight: 300;
  vertical-align: top;
}
</style>
